.login-form {
  width: 615px;
  margin: auto;

  .errmsg {
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    background-color: rgba(175, 45, 45, 0.5);
    border-radius: 10px;
    padding: 15px;
  }

  &-text,
  &-password,
  &-submit {
    text-align: center;
    border-radius: initial;
    font-size: 20px;
    line-height: 33px;

    &:hover,
    &:focus {
      border: 1px solid #af2418;
      box-shadow: none;
    }

    &::placeholder {
      text-align: center;
      color: #f0f0f0;
    }
  }

  &-text,
  &-password {
    border: 1px solid #707070;
    width: 615px;
    height: 114px;
    color: #222;
  }

  &-password {
    border-top: none;
  }

  &-submit {
    background-color: #af2418;
    border-color: transparent;
    width: 615px;
    height: 114px;
    color: #fff;
  }

  .form-check-input {
    border-radius: initial;
    width: 26px;
    height: 26px;
    margin-right: 10px;

    &:checked {
      background-color: #707070;
      color: #707070;
      border-color: #707070;
    }

    &:focus {
      border-color: #707070;
      box-shadow: none;
    }
  }

  &-lost-password,
  &-remember-me {
    font-size: 20px;
    line-height: 33px;
    color: #707070;
    text-decoration: none;
  }
}
