
@font-face {
  font-family: "Thin";
  src: local("Catamaran"),
    url("../fonts/Catamaran/Catamaran-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "ExtraLight";
  src: local("Catamaran"),
    url("../fonts/Catamaran/Catamaran-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Light";
  src: local("Catamaran"),
    url("../fonts/Catamaran/Catamaran-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Regular";
  src: local("Catamaran"),
    url("../fonts/Catamaran/Catamaran-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Medium";
  src: local("Catamaran"),
    url("../fonts/Catamaran/Catamaran-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "SemiBold";
  src: local("Catamaran"),
    url("../fonts/Catamaran/Catamaran-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Bold";
  src: local("Catamaran"),
    url("../fonts/Catamaran/Catamaran-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "ExtraBold";
  src: local("Catamaran"),
    url("../fonts/Catamaran/Catamaran-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Black";
  src: local("Catamaran"),
    url("../fonts/Catamaran/Catamaran-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Lobster Two";
  src: local("LobsterTwo"),
    url("../fonts/LobsterTwo/LobsterTwo-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Lobster Two";
  src: local("LobsterTwo"),
    url("../fonts/LobsterTwo/LobsterTwo-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Lobster Two";
  src: local("LobsterTwo"),
    url("../fonts/LobsterTwo/LobsterTwo-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Lobster Two";
  src: local("LobsterTwo"),
    url("../fonts/LobsterTwo/LobsterTwo-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

body,
p,
span,
a,
button,
div,
ul,
li {
  font-family: 'Catamaran', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lobster Two', sans-serif;
}

.lgbt {
  &-row {
    display: flex;
    align-items: center;
    min-height: 130px;
    padding: 40px;
  }

  &-bg-grey {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &-title {
    font-size: 40px;
    color: #000;
    line-height: 50px;
    font-weight: 700;
    font-family: 'Lobster Two', sans-serif;
  }

  &-label {
    font-size: 25px;
    color: #000;
    line-height: 31px;
    font-weight: 700;
    font-family: 'Lobster Two', sans-serif;
    min-width: 150px;
    display: inline-block;
  }

  &-text,
  &-textupd,
  &-datelocal,
  &-textarea,
  &-longtext {
    text-transform: uppercase;
    font-size: 25px;
    color: #707070;
    line-height: 42px;
    font-weight: 400;
    font-family: 'Catamaran', sans-serif;
  }

  &-datelocal {
    width: 50%;
  }

  &-textarea {
    width: 100%;
  }

  &-textupd,
  &-datelocal,
  &-textarea {
    border: none;
    border-bottom: 1px dashed #000;
    background: none !important;

    &:focus,
    &:focus-visible {
      border: none;
      outline: none;
      background: #fff !important;
      color: #707070;
    }
  }

  &-action {
    &-icon {
      font-size: 28px;
      margin-right: 10px;
      color: #AF2418;
    }

    &-label {
      font-size: 20px;
      line-height: 33px;
      color: #707070;
    }
  }
}

/* CONFIRM BOX */
.react-confirm-alert-body {
  border: 5px solid #AF2418;
  border-radius: inherit;
  text-align: center;
  padding: 50px;
  font-size: 30px;
  line-height: 38px;
  width: auto;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.react-confirm-alert-button-group {
  justify-content: center;

  button {
    font-family: 'Lobster Two', sans-serif;
    font-size: 30px;
    line-height: 38px;
  }

  button:first-child {
    background-color: #AF2418;
    color: #fff;
    margin-right: 60px;
  }
}