.customersearchpage {
  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 103px);
    overflow: auto;
  }

  &-inputs {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50%;
    border: 5px solid #AF2418;
  }

  &-inputtext {
    height: 100%;
    width: calc(100% - 150px);
    border: none;
    font-size: 50px;
    padding: 40px;
    color: #707070;

    &:focus {
      border: none;
    }

    &:focus-visible {
      outline: none;
    }

    &::-webkit-input-placeholder {
      opacity: 0.26;
    }
  }
  
  &-inputsubmit {
    height: 100%;
    color: #fff;
    background: #AF2418;
    border: none;
    padding: 22px 40px;
    font-size: 70px;
  }

  &-suggestions {
    position: absolute;
    top: 100%;
    left: -5px;
    width: calc((100% - 150px) + 10px);
    background-color: #fff;
    z-index: 10;

    &-row {
      border: 1px solid #AF2418;
      padding: 20px;
      font-size: 40px;
      text-transform: uppercase;
    }
  }

  &-newCustomer {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    padding: 20px;
    color: #AF2418;
    font-size: 35px;
    margin-top: 30px;
  }
}