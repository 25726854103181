.loginpage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  &-left {
    width: 50%;
    height: 100vh;
  }

  &-right {
    width: 50%;
  }
}