.customer-nav {
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #fff;
  z-index: 10;
  border-radius: 5px;

  &-btn {
    background: transparent;
    border: none;
    color: #AF2418;
    font-size: 25px;
    line-height: 25px;

    &:hover,
    &:focus,
    &:active {
      background-color: #e82826 !important;
      color: #fff !important;
    }

    &::after {
      display: none;
    }
  }

  .dropdown-item {
    &:hover,
    &:focus,
    &:active {
      background-color: #e82826 !important;
      color: #fff !important;
    }
  }
}