.customerpage,
.depotpage {
  &-wrapper {
    min-height: calc(100% - 100px);
    height: calc(100vh - 103px);
    overflow: auto;
    overflow-x: hidden;
  }

  &-button {
    background: rgb(175, 36, 24);
    padding: 20px;
    width: 33%;
    display: block;
    text-align: center;
    color: #fff;
    font-family: 'Lobster Two';
    font-size: 25px;
    line-height: 31px;
    border: none;
  }
}