/*SUPPRESSION DU HOVER PAR DEFAUT */
.fsBPnz:hover,
.fsBPnz:focus,
.fsBPnz:hover span,
.fsBPnz:focus span,
.fsBPnz:hover span.__rdt_custom_sort_icon__ *,
.fsBPnz:focus span.__rdt_custom_sort_icon__ * {
  opacity: 1 !important;
}

.rdt_TableHeadRow.iSAVrt {
  height: 103px;
  padding: 36px 0;
  background-color: rgba(112, 112, 112, 0.05);
}

.rdt_TableRow {
  height: 83px;
  background-color: transparent !important;

  &:nth-child(2n + 2) {
    .rdt_TableCell {
      background-color: rgba(112, 112, 112, 0.05);
    }
  }
}

.rdt_TableCol {
  justify-content: center;

  div {
    font-family: 'Lobster Two', sans-serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 31px;
    color: #000;
  }
}

.rdt_TableCell {
  color: #707070;
  font-size: 20px;
  justify-content: center;

  button {
    border: none;
    background: transparent;
    font-size: 25px;
    color: #AF2418;
  }
}

.datatable-search {
  border: 2px solid #AF2418 !important;
  padding: 25px 10px !important;
  border-radius: 0 !important;
  border-right: 0 !important;
}

.datatable-clear {
  border: 2px solid #AF2418 !important;
  border-radius: 0 !important;
  padding: 25px;
  color: #fff;
  background-color: #AF2418;
}

input.datatable-search::-webkit-input-placeholder {
  color: rgba(112, 112, 112, 0.26) !important;
}

input.datatable-search:focus-visible {
  outline: none !important;
}


/* Change la taille de la barre de défilement */
::-webkit-scrollbar {
  width: 48px;
}

/* Change la couleur de la barre de défilement */
::-webkit-scrollbar-thumb {
  background-image: url("../../images/scrollbar.png");
  background-repeat: no-repeat;
  background-position: center;
  border: 7px solid #000;

  &:before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    width: 2px;
    height: 8px;
    background: #000;
  }
}

::-webkit-scrollbar-track {
  background: #000;
}

::-webkit-scrollbar-thumb::before {
  left: -5px;
}

::-webkit-scrollbar-thumb::after {
  left: 5px;
}

.datatable-without-filter {
  header {
    display: none;
  }
}