.dashboardpage {
  &-wrapper {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.66);
  }

  &-left,
  &-center,
  &-right {
    position: absolute;
    overflow: hidden;
    width: 33%;
    height: 100vh;
    cursor: pointer;
    opacity: 1;
    border: 1px solid #fff;
    overflow: visible;
  }

  &-left {
    top: 0;
    left: 0;

    &-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 50px;
      text-align: center;
    }
  }

  &-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 50px;
      text-align: center;
    }
  }

  &-right {
    top: 0;
    right: 0;

    &-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 50px;
      text-align: center;
    }
  }
}