.category-wrapper {
    display: flex;
    height: 100vh;
}
  
.category-unique {
    display: block;
    width: 80%;
    height: 100%;
    overflow: auto;
    white-space: nowrap;
    
    &-content {
        padding: 50px 100px;

        &-title {
            background-color: #000;
            color: #fff;
            font-size: 25px;
            padding: 10px;
        }

        &-search {
            display: flex;
            align-items: center;

            input {
                width: 90%;
                height: 58px;
                border: 3px solid #000;
                padding: 5px 20px;
                font-size: 25px;

                &::placeholder {
                    color: #707070;
                    font-size: 25px;
                }
            }

            &-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 10%;
                background-color: #000;
                color: #fff;
                height: 58px;
                font-size: 10px;
            }
        }

        &-post {
            margin: 50px 0;

            &-image {
                max-width: 100%;
                margin-bottom: 20px;
            }

            &-title {
                font-weight: 800;
                font-size: 25px;
            }

            &-button {
                background-color: #AF2418;
                color: #fff;
                border: 0;
                font-size: 20px;
                padding: 10px 20px;
            }
        }
    }
}