.header {
  &-wrapper {
    position: relative;
    height: 103px;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    background-color: #AF2418;
  }

  &-title {
    font-size: 50px;
    line-height: 63px;
  }

  &-backarrow {
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translate(0, -50%);
    color: #fff;
    font-size: 30px;
    cursor: pointer;
  }

  &-closeIcon {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translate(0, -50%);
    color: #fff;
    font-size: 30px;
    cursor: pointer;
  }
}
