.tasks-wrapper {
  display: flex;
  height: 100vh;
}

.tasksList {
  display: block;
  width: 80%;
  height: 100%;
  overflow: auto;
}

.linkDashboard {
  display: block;
  width: 20%;
  height: 100vh;
  cursor: pointer;
}

.task-timer {
  display: flex;
  align-items: center;
  color: #707070 !important;
  margin: 0 15px;

  &-bulle {
    margin-right: 5px;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    background-color: #00FF87;
    animation: fadeInOut 3s linear infinite;
    opacity: 0;
    visibility: hidden;

    &.pause {
      background-color: #F8A965 !important;
      animation: none;
      opacity: 1;
      visibility: visible;
    }
  }
}

.legends-task {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: -20px;

  &-text {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
  }

  &-timer-bulle {
    display: block;
    width: 20px;
    height: 20px;
    background-color: #00FF87;
    border-radius: 100%;
    margin-right: 5px;
    
    &-pause {
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: #F8A965;
      margin-right: 5px;
    }
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  50% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}