.background-truck {
  position: absolute;
  z-index: -1;
  background-image: url("../../images/truck.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
  transition: background-position 2s;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}