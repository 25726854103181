.diagnostic {
  display: block;
  height: 100%;
  overflow: auto;
  white-space: nowrap;

  &-mini-title {
    font-size: 40px !important;
  }

  &-table {
    &-prestation {
      width: 100%;
      margin-top: 30px;

      thead {
        padding: 20px;

        tr {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
      }

      &-body {
        margin-top: 50px;

        tr {
          td {
            font-size: 25px;
            color: #707070;
          }
        }
      }
    }
  }

  &-questions {
    margin-top: 100px;
    border: 1px solid #275841;
    background-color: #e3e9e6;
    padding: 0;

    &-row {
      position: relative;
      margin: 50px 100px 50px;

      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          top: calc(100% + 50px);
          left: 0px;
          width: calc(100% - 200px);
          height: 2px;
          border: 1px dashed #2d5d47;
        }
      }
    }
  }

  &-question,
  &-answer {
    font-family: 'Catamaran', sans-serif;
    font-size: 25px;
    line-height: 42px;
    color: #000;
  }

  &-question {
    margin-right: 20px;
  }

  &-answer {
    font-weight: 700;
    text-transform: uppercase;
  }
}
