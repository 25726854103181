.process-wrapper {
  display: flex;
  height: 100vh;
}

.process-content {
  display: block;
  width: 80%;
  height: 100%;
  overflow: auto;
  white-space: nowrap;

  &-search {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 80%;

    &-input {
      display: flex;
      align-items: center;
      position: relative;

      input {
        width: 60vw;
        height: 167px;
        border: 5px solid #AF2418;
        font-size: 50px;
        padding: 40px;

        &::placeholder {
          color: rgba(112, 112, 112, 0.26);
        }
      }
    }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 167px;
      height: 167px;
      background-color: #AF2418;
      cursor: pointer;

      svg {
        color: #fff;
      }
    }

    &-suggestions {
      position: absolute;
      top: 100%;
      width: 60vw;
      margin-top: -5px;
      background-color: #fff;
      z-index: 10;

      &-option {
        border: 1px solid #AF2418;
        padding: 20px;
        font-size: 40px;
        text-transform: uppercase;
      }
    }
  }

  &-categories {
    margin-top: 50px;

    svg {
      cursor: pointer;
      width: 70px;
      padding: 15px;
      background-color: #AF2418;
      border-radius: 100%;
      color: #fff;
      margin: 0 23px;
    }
  }
}

.process-unique {
  width: 80%;
  overflow-y: auto;

  &-content {
    padding: 100px;

    &-post {
      margin-bottom: 70px;

      &-title {
        font-weight: 800;
        font-size: 40px;
      }

      &-image {
        max-width: 100%;
      }

      &-content {
        font-size: 25px;
        color: #707070;
      }
    }
  }
}

.linkDashboard {
  display: block;
  width: 20%;
  height: 100vh;
  cursor: pointer;
}
