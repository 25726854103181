.taskpage-wrapper {
  display: flex;
  height: 100vh;
}

.taskContent {
  display: block;
  width: 80%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.linkDashboard {
  display: block;
  width: 20%;
  height: 100vh;
}

.task-customercard {
  background-color: #af2418;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;

  &-title {
    font-family: "Lobster Two", sans-serif;
    font-size: 40px;
    line-height: 50px;
  }

  &-label {
    font-size: 25px;
    line-height: 40px;
  }
}

.task-left-card,
.task-right-card {
  &-header {
    text-align: center;
    padding: 20px;

    span {
      color: #fff;
      font-family: "Lobster Two", sans-serif;
      font-size: 40px;
      line-height: 50px;
    }
  }

  &-body {
    min-height: 500px;
    padding: 50px;
  }
}

.task-left-card {
  border: 1px solid #275841;

  &-header {
    background-color: #275841;
  }

  &-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #e3e9e6;

    &-question {
      padding: 30px;
      margin-bottom: 120px;
      font-size: 25px;
      line-height: 42px;
      text-align: center;
    }

    &-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    &-button {
      color: #fff;
      background-color: #275841;
      font-family: "Catamaran", sans-serif;
      text-align: center;
      font-size: 25px;
      line-height: 42px;
      text-transform: uppercase;
      border: none;
      padding: 17px 0;
      width: calc(50% - 17px);
    }
  }
}

.task-right-card {
  border: 1px solid #e0744b;

  &-header {
    background-color: #e0744b;
  }

  &-body {
    max-height: 1000px;
    overflow: auto;
    background: #fbede8;

    &-post {
      position: relative;
      margin-bottom: 60px;

      &-title {
        font-weight: 700;
        margin-bottom: 10px;
      }

      &-image {
        object-fit: contain;
        width: 100%;
        margin-bottom: 10px;
      }

      &-text {
        font-size: 25px;
        line-height: 42px;
        color: #707070;
      }

      &::after {
        content: "";
        position: absolute;
        top: calc(100% + 30px);
        left: 50%;
        transform: translate(-50%, -50%);
        width: 75%;
        height: 2px;
        border: 1px dashed #e0744b;
      }
    }
  }

  /* Change la taille de la barre de défilement */
  ::-webkit-scrollbar {
    width: 48px !important;
  }

  /* Change la couleur de la barre de défilement */
  ::-webkit-scrollbar-thumb {
    background-image: url("../../images/scrollbar-orange.png") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    border: 7px solid #f6d5c9 !important;

    &:before,
    &::after {
      content: "" !important;
      display: block !important;
      position: absolute !important;
      top: 50% !important;
      width: 2px !important;
      height: 8px !important;
      background: #f6d5c9 !important;
    }
  }

  ::-webkit-scrollbar-track {
    background: #f6d5c9 !important;
  }

  ::-webkit-scrollbar-thumb::before {
    left: -5px !important;
  }

  ::-webkit-scrollbar-thumb::after {
    left: 5px !important;
  }
}
